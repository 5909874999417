
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AdminClient, HeadquartersClient, VanClient } from '@/services/Services';
import { showNotification } from '@/utils';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class BaseVanInfo extends Vue {

    @Prop({
        default: ""
    }) vanIdentifier: string;
    localVanIdentifier: string = "";

    van: OM.VanVm = new OM.VanVm();
    headquartersList: OM.TextIdentifier[] = [];
    allEmployees: OM.TextIdentifier[] = [];
    assignmentDriverId: string = null;

    created() {
        this.localVanIdentifier = this.vanIdentifier;

        Promise.all([
            AdminClient.getAllUsersAsSelect(),
            HeadquartersClient.getAllAsSelect()
        ])
        .then(xs => {
            this.allEmployees = xs[0];
            this.headquartersList = xs[1];

            this.init();
        })
    }

    init() {
        if(!this.vanIdentifier)
            return;

        Promise.all([
            VanClient.getVan(this.vanIdentifier),
            VanClient.getAssignmentDriver(this.vanIdentifier)
        ])
        .then(xs => {
            this.van = xs[0];
            this.assignmentDriverId = xs[1] != null ? xs[1].identifier : null;
        })
    }

    get saveDisabled() {
        return !this.van.headquarterIdentifier || !this.van.plate || !this.van.sigla;
        // || !this.assignmentDriverId
    }

    save() {
        if(!this.localVanIdentifier)
            this.van.vanId = null;

        this.van.headquarterName = this.headquartersList.filter(x => x.identifier == this.van.headquarterIdentifier)[0].text;
        VanClient.updateVan(this.van)
        .then(x => {
            this.localVanIdentifier = x;

            showNotification(this.$localizationService.getLocalizedValue("Successo", "Successo"), this.$localizationService.getLocalizedValue("Informazioni salvate con successo", "Informazioni salvate con successo"));

            this.$emit('updateIdentifier', this.localVanIdentifier);
            this.init();

            // VanClient.updateEmployeeAssignment(this.localVanIdentifier, this.assignmentDriverId)
            // .then(x => {
            //     showNotification(this.$localizationService.getLocalizedValue("Successo", "Successo"), this.$localizationService.getLocalizedValue("Informazioni salvate con successo", "Informazioni salvate con successo"));

            //     this.$emit('updateIdentifier', this.localVanIdentifier);
            //     this.init();
            // })
        })
    }

}
