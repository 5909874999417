
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AttachmentDDT from './attachmentDDT.vue';
import AttachmentBooklet from './attachmentBooklet.vue';
import AttachmentReportReturn from './attachmentReportReturn.vue';

@Options({
    components: {
        AttachmentDDT,
        AttachmentBooklet,
        AttachmentReportReturn
    }
})
export default class DocumentsInfo extends Vue {

    @Prop({
        default: ""
    }) vanIdentifier: string;

    created() {}

}
