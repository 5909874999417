import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row w-100" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex justify-content-center mt-5"
}
const _hoisted_3 = {
  key: 1,
  class: "text-center pt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vanInfo.info, (value, name, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col-sm-6",
          key: index
        }, [
          _createVNode(_component_form_field, {
            type: "text",
            labelKey: name,
            labelValue: name,
            placeholderKey: name,
            placeholderValue: name,
            modelValue: _ctx.localVanInfo[name],
            "onUpdate:modelValue": ($event: any) => ((_ctx.localVanInfo[name]) = $event)
          }, null, 8, ["labelKey", "labelValue", "placeholderKey", "placeholderValue", "modelValue", "onUpdate:modelValue"])
        ]))
      }), 128))
    ]),
    (!_ctx.isEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "btn btn-primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save()))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Salva",
              text: "Salva"
            })
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Nessuna informazione da inserire",
              text: "Nessuna informazione da inserire"
            })
          ])
        ]))
  ]))
}