import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_document_management = _resolveComponent("document-management")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_localized_text, {
      class: "d-block fs_20 fw_700 mb-4",
      localizedKey: "Libretto",
      text: "Libretto"
    }),
    (_openBlock(), _createBlock(_component_document_management, {
      sectionTitle: "Documenti (PDF, PNG, JPG, JPEG)",
      onlyImage: false,
      urlAttachments: [_ctx.attachment],
      onNewFileAdded: _ctx.editFile,
      onFileUpdated: _ctx.editFile,
      key: _ctx.rnd
    }, null, 8, ["urlAttachments", "onNewFileAdded", "onFileUpdated"]))
  ]))
}