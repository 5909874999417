
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { VanClient } from '@/services/Services';
import EditNoteModal from '@/components/modals/editNoteModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class NoteInfo extends Vue {

    @Prop({
        default: ""
    }) vanIdentifier: string;

    allNotes: OM.NoteVm[] = [];

    created() {
        this.init();
    }

    init() {
        if(!this.vanIdentifier)
            return;

        VanClient.getVanNotes(this.vanIdentifier)
        .then(x => {
            this.allNotes = x;
        })
    }

    editNote(note: OM.NoteVm) {
        if(note == null)
            note = new OM.NoteVm();

        this.$opModal.show(EditNoteModal, {
            showTypology: false,
            note: note,
            callback: (isPrivate: boolean, text: string) => {
                var proms = [];

                if(!note.id)
                    proms.push(VanClient.addNotes(this.vanIdentifier, text));
                else
                    proms.push(VanClient.updateVanNote(this.vanIdentifier, note.id, text));

                Promise.all(proms)
                .then(xs => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

}
