
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { VanClient } from '@/services/Services';
import DocumentManagement from '@/components/documentsManagement.vue';
import * as OM from '@/Model';

@Options({
    components: {
        DocumentManagement
    }
})
export default class AttachmentDDT extends Vue {

    @Prop({
        default: ""
    }) vanIdentifier: string;

    attachment: OM.UrlAttachment = new OM.UrlAttachment();
    rnd: number = 0;

    created() {
        this.init();
    }

    init() {
        VanClient.getDDTFile(this.vanIdentifier)
        .then(x => {
            if(x != null)
                this.attachment = x;

            this.rnd = Math.random();
        })
    }

    editFile(newJobContractFile: File) {
        if(!newJobContractFile)
            return;

        VanClient.updateDDTFile(newJobContractFile, this.vanIdentifier)
        .then(x => {
            this.init();
        })
    }

}
