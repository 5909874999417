
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { VanClient } from '@/services/Services';
import DocumentManagement from '@/components/documentsManagement.vue';
import * as OM from '@/Model';

@Options({
    components: {
        DocumentManagement
    }
})
export default class EditInsuranceModal extends Vue {

    @Prop() vanIdentifier: string;
    @Prop() insurance: OM.Insurance;
    @Prop() isEdit: boolean;

    localInsurance: OM.Insurance = new OM.Insurance();
    localDocument: OM.UrlAttachment = new OM.UrlAttachment();
    localIsEdit: boolean = false;
    oldExpirationDate: string = "";

    @Prop() callback: any;

    rnd: number = 0;

    mounted() {
        this.localInsurance = {...this.insurance};
        this.localDocument = {...this.insurance.urlAttachment};
        this.localIsEdit = this.isEdit;

        if(this.localIsEdit)
            this.oldExpirationDate = this.localInsurance.expiration;

        if(!this.localDocument)
            this.localDocument = new OM.UrlAttachment();
    }

    editFile(newDocumentFile: File) {
        if(!newDocumentFile)
            return;

        VanClient.updateInsuranceFile(newDocumentFile, this.vanIdentifier, this.localInsurance.expiration)
        .then(x => {
            this.localDocument = x;
            this.rnd = Math.random();
        })
    }

    get saveDisabled() {
        return !this.localInsurance.expiration;
    }

    save() {
        if(!this.localIsEdit) {
            VanClient.addInsurance(this.vanIdentifier, this.localInsurance.expiration)
            .then(x => {
                this.localIsEdit = true;
                this.oldExpirationDate = this.localInsurance.expiration;
            })
        }
        else {
            VanClient.updateInsuranceExpiration(this.vanIdentifier, this.oldExpirationDate, this.localInsurance.expiration)
            .then(x => {
                this.callback();
            })
        }
    }

}
