
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { InsuranceClaimEventClient } from '@/services/Services';
import InsuranceClaimEventDetailModal from '../modals/insuranceClaimEventDetailModal.vue';
import AddNoteToInsuranceModal from '../modals/addNoteToInsuranceModal.vue';
import * as OM from '@/Model';
import { showNotification } from '@/utils';

@Options({
    components: {}
})
export default class InsuranceClaimEventInfo extends Vue {

    @Prop({
        default: ""
    }) vanIdentifier: string;

    list: OM.BaseVehicleEventVm[] = [];

    created() {
        this.init();
    }

    init() {
        if(!this.vanIdentifier)
            return;

        InsuranceClaimEventClient.getAllByVanId(this.vanIdentifier)
        .then(x => {
            this.list = x;
        })
    }

    openDetails(eventId: string) {
        this.$opModal.show(InsuranceClaimEventDetailModal, {
            eventIdentifier: eventId
        })
    }

    addNote(eventId: string) {
        this.$opModal.show(AddNoteToInsuranceModal, {
            callback: (note: string) => {
                InsuranceClaimEventClient.addNotes(eventId, note)
                .then(x => {
                    this.$opModal.closeLast();
                    showNotification(this.$localizationService.getLocalizedValue("Successo", "Successo"), this.$localizationService.getLocalizedValue("Nota aggiunta con successo", "Nota aggiunta con successo"));
                    this.init();
                })
            }
        })
    }

}
