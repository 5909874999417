
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { VanClient } from '@/services/Services';
import { showNotification } from '@/utils';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class AdditionalInfo extends Vue {

    @Prop({
        default: ""
    }) vanIdentifier: string;

    vanInfo: OM.VanAdditionalInfoVM = new OM.VanAdditionalInfoVM();
    localVanInfo : { [key: string]: string; } = {};

    created() {
        this.init();
    }

    init() {
        if(!this.vanIdentifier)
            return;

        VanClient.getVanAdditionalInfo(this.vanIdentifier)
        .then(x => {
            this.vanInfo = x;

            var propertyList = Object.keys(this.vanInfo.info);
            propertyList.forEach(element => {
                this.localVanInfo[element] = this.vanInfo.info[element];
            });
        })
    }

    get isEmpty() {
        var keys = Object.keys(this.localVanInfo);
        return keys.length == 0;
    }

    save() {
        var vm = new OM.ItemUpdatePropertiesVM();
        vm.itemIdentifier = this.vanIdentifier;
        vm.propertiesToUpdate = this.localVanInfo;

        VanClient.updateManyProperties(vm)
        .then(x => {
            showNotification(this.$localizationService.getLocalizedValue("Successo", "Successo"), this.$localizationService.getLocalizedValue("Informazioni salvate con successo", "Informazioni salvate con successo"));

            this.init();
        })
    }

}
