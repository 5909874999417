
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { VanClient } from '@/services/Services';
import EditInsuranceModal from '../modals/editInsuranceModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class InsuranceInfo extends Vue {

    @Prop({
        default: ""
    }) vanIdentifier: string;

    insurancesList: OM.Insurance[] = [];

    created() {
        this.init();
    }

    init() {
        VanClient.getAllInsurances(this.vanIdentifier)
        .then(x => {
            this.insurancesList = x;
        })
    }

    editInsurance(insurance: OM.Insurance) {
        var isEdit = true;

        if(insurance == null) {
            insurance = new OM.Insurance();
            isEdit = false;
        }

        this.$opModal.show(EditInsuranceModal, {
            vanIdentifier: this.vanIdentifier,
            insurance: insurance,
            isEdit: isEdit,
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

}
