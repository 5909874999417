import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/arrow.left.svg'


const _hoisted_1 = { class: "page_header" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "foldersTabContainer" }
const _hoisted_4 = { class: "formContainer withFolders" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_base_van_info = _resolveComponent("base-van-info")!
  const _component_additional_info = _resolveComponent("additional-info")!
  const _component_insurance_info = _resolveComponent("insurance-info")!
  const _component_documents_info = _resolveComponent("documents-info")!
  const _component_insurance_claim_event_info = _resolveComponent("insurance-claim-event-info")!
  const _component_note_info = _resolveComponent("note-info")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "pointer me-3",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
        }),
        (!_ctx.vanIdentifier)
          ? (_openBlock(), _createBlock(_component_localized_text, {
              key: 0,
              type: 'h1',
              localizedKey: "Crea van",
              text: "Crea van",
              class: "mb-0"
            }))
          : (_openBlock(), _createBlock(_component_localized_text, {
              key: 1,
              type: 'h1',
              localizedKey: "Modifica van",
              text: "Modifica van",
              class: "mb-0"
            }))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["tab", {'active' : _ctx.activeSection == 1}]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeSection = 1))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Dati van",
          text: "Dati van"
        })
      ], 2),
      (_ctx.vanIdentifier)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["tab", {'active' : _ctx.activeSection == 2}]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activeSection = 2))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Informazioni aggiuntive",
              text: "Informazioni aggiuntive"
            })
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.vanIdentifier)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["tab", {'active' : _ctx.activeSection == 3}]),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.activeSection = 3))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Assicurazione",
              text: "Assicurazione"
            })
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.vanIdentifier)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["tab", {'active' : _ctx.activeSection == 4}]),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.activeSection = 4))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Documenti",
              text: "Documenti"
            })
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.vanIdentifier)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass(["tab", {'active' : _ctx.activeSection == 5}]),
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.activeSection = 5))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Sinistri",
              text: "Sinistri"
            })
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.vanIdentifier)
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            class: _normalizeClass(["tab", {'active' : _ctx.activeSection == 6}]),
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.activeSection = 6))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Note",
              text: "Note"
            })
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.activeSection == 1)
        ? (_openBlock(), _createBlock(_component_base_van_info, {
            key: 0,
            vanIdentifier: _ctx.vanIdentifier,
            onUpdateIdentifier: _ctx.updateIdentifierValue
          }, null, 8, ["vanIdentifier", "onUpdateIdentifier"]))
        : _createCommentVNode("", true),
      (_ctx.activeSection == 2)
        ? (_openBlock(), _createBlock(_component_additional_info, {
            key: 1,
            vanIdentifier: _ctx.vanIdentifier
          }, null, 8, ["vanIdentifier"]))
        : _createCommentVNode("", true),
      (_ctx.activeSection == 3)
        ? (_openBlock(), _createBlock(_component_insurance_info, {
            key: 2,
            vanIdentifier: _ctx.vanIdentifier
          }, null, 8, ["vanIdentifier"]))
        : _createCommentVNode("", true),
      (_ctx.activeSection == 4)
        ? (_openBlock(), _createBlock(_component_documents_info, {
            key: 3,
            vanIdentifier: _ctx.vanIdentifier
          }, null, 8, ["vanIdentifier"]))
        : _createCommentVNode("", true),
      (_ctx.activeSection == 5)
        ? (_openBlock(), _createBlock(_component_insurance_claim_event_info, {
            key: 4,
            vanIdentifier: _ctx.vanIdentifier
          }, null, 8, ["vanIdentifier"]))
        : _createCommentVNode("", true),
      (_ctx.activeSection == 6)
        ? (_openBlock(), _createBlock(_component_note_info, {
            key: 5,
            vanIdentifier: _ctx.vanIdentifier
          }, null, 8, ["vanIdentifier"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}