
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { InsuranceClaimEventClient, VanClient } from '@/services/Services';
import DocumentEditModal from '@/components/modals/documentEditModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class InsuranceClaimEventDetailModal extends Vue {

    @Prop() eventIdentifier: string;

    detailInfo: OM.InsuranceClaimEvent = new OM.InsuranceClaimEvent();

    created() {
        InsuranceClaimEventClient.getByEventId(this.eventIdentifier)
        .then(x => {
            this.detailInfo = x;
        })
    }

    openViewModal(attachment: OM.UrlAttachment) {
        this.$opModal.show(DocumentEditModal, {
            document: attachment,
            editMode: false
        })
    }

}
