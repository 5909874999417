
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class AddNoteToInsuranceModal extends Vue {

    @Prop() callback: any;

    newNote: string = "";

    created() {}

    save() {
        this.callback(this.newNote);
    }

}
