
import { Options, Vue } from 'vue-class-component';
import BaseVanInfo from './components/baseVanInfo.vue';
import AdditionalInfo from './components/additionalInfo.vue';
import InsuranceInfo from './components/insuranceInfo.vue';
import DocumentsInfo from './components/documentsInfo.vue';
// import DriverInfo from './components/driverInfo.vue';
// import AssignmentsInfo from './components/assignmentsInfo.vue';
import InsuranceClaimEventInfo from './components/insuranceClaimEventInfo.vue';
import NoteInfo from './components/noteInfo.vue';

@Options({
    components: {
        BaseVanInfo,
        AdditionalInfo,
        InsuranceInfo,
        DocumentsInfo,
        InsuranceClaimEventInfo,
        NoteInfo
    }
})
export default class EditVan extends Vue {

    vanIdentifier: string = "";
    activeSection: number = 1;

    created() {
        this.vanIdentifier = this.$route.params.vanIdentifier ? this.$route.params.vanIdentifier.toString() : "";
    }

    updateIdentifierValue(idValue: string) {
        this.vanIdentifier = idValue;
    }

}
