import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-6" }
const _hoisted_3 = { class: "col-sm-6" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-sm-10" }
const _hoisted_6 = { class: "col-sm-2" }
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "form-group me-5" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "d-flex justify-content-center mt-5" }
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_form_field, {
          type: "select",
          labelKey: "Magazzino",
          labelValue: "Magazzino",
          placeholderKey: "Magazzino",
          placeholderValue: "Magazzino",
          required: true,
          modelValue: _ctx.van.headquarterIdentifier,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.van.headquarterIdentifier) = $event)),
          list: _ctx.headquartersList
        }, null, 8, ["modelValue", "list"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_form_field, {
          type: "text",
          labelKey: "Sigla",
          labelValue: "Sigla",
          placeholderKey: "Sigla",
          placeholderValue: "Sigla",
          required: true,
          modelValue: _ctx.van.sigla,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.van.sigla) = $event))
        }, null, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_form_field, {
          type: "text",
          labelKey: "Targa",
          labelValue: "Targa",
          placeholderKey: "Targa",
          placeholderValue: "Targa",
          required: true,
          modelValue: _ctx.van.plate,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.van.plate) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_localized_text, {
              type: "label",
              localizedKey: "Ztl",
              text: "Ztl"
            }),
            _createElementVNode("div", {
              class: _normalizeClass(["sameAsCheckbox", {'active' : _ctx.van.isZtl}]),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.van.isZtl = !_ctx.van.isZtl))
            }, null, 2)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_localized_text, {
              type: "label",
              localizedKey: "Disponibile",
              text: "Disponibile"
            }),
            _createElementVNode("div", {
              class: _normalizeClass(["sameAsCheckbox", {'active' : _ctx.van.isAvailable}]),
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.van.isAvailable = !_ctx.van.isAvailable))
            }, null, 2)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.save())),
        disabled: _ctx.saveDisabled
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Salva",
          text: "Salva"
        })
      ], 8, _hoisted_11)
    ])
  ]))
}